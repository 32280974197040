
export default {
	name: 'LoginForm',
	data() {
		return {
			form: {
				username: '',
				password: '',
			},
			invalidInput: false,
			invalidInputIndicate: false,
			shake: false,
		}
	},
	methods: {
		login() {
			if (this.form.username == '' || this.form.password == '' || this.invalidInput) {
				this.invalidInput = true
				this.invalidInputIndicate = true
				this.shake = true
				return
			}
			this.$api.disableLogoutInterceptor()
			this.$api.login(this.form.username, this.form.password)
			.then((response: any) => {
				this.$store.dispatch('login', { api: this.$api, authToken: response.data.access_token, })
				this.$router.push('/country-select')
			}).catch((error: any) => {
				if (error.response.status == 401) {
					this.invalidInput = true
					this.invalidInputIndicate = true
					this.shake = true
				} else {
					console.log(error)
				}
			}).finally(() => {
				this.$api.enableLogoutInterceptor()
			})
		},
	}
}
