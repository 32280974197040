import { createStore } from 'vuex'

export default createStore({
	state: {
		country: '',
		authToken: '',
		authenticated: false,
	},
	mutations: {
		setCountry(state, iso) {
			state.country = iso
		},
		unsetCountry(state) {
			state.country = ''
		},
		setAuthenticated(state, token) {
			state.authToken = token
			state.authenticated = true
		},
		unsetAuthenticated(state) {
			state.authToken = ''
			state.authenticated = false
		},
	},
	actions: {
		login(context, { api, authToken }) {
			context.commit('setAuthenticated', authToken)
			api.client.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
			localStorage.setItem('authToken', authToken)
		},
		logout(context, api) {
			context.commit('unsetAuthenticated')
			delete api.client.defaults.headers.common['Authorization']
			localStorage.removeItem('authToken')
		},
		setCountry(context, { api, country }) {
			api.checkCountryIso(country)
			.then((response: any) => {
				if (response.data) {
					context.commit('setCountry', country)
				} else {
					context.commit('setCountry', '')
				}
			}).catch((error: any) => {
				context.commit('setCountry', '')
				console.log(error)
			}).finally(() => {})
		},
	}
});
