import { createApp, provide } from 'vue'
import App from './App.vue'
import Api from '@/plugins/api.ts'
import router from './router'
import store from './store'
import axios from 'axios'
import i18n from './i18n'
import './assets/main.css';

let app = createApp(App).use(i18n)
const api = new Api()
app.config.globalProperties.$api = api
app = app.use(router).use(store)

// Check for a stored (valid) auth token by setting the token and trying a request
const authToken = localStorage.getItem('authToken')
if (authToken != null) {
	store.dispatch('login', { api: api, authToken: authToken })
	api.getUser()
	.then(() => {})
	// TODO handling codes != 401?
	.catch(() => {
		store.dispatch('logout', api)
	})
}

// Route guards to prevent unauthenticated access to deep links
router.beforeEach((to, from, next) => {
	api.disableLogoutInterceptor()
	let authenticated: boolean = false
	api.getUser()
	.then(() => { authenticated = true })
	// TODO handling codes != 401?
	.catch(() => { authenticated = false })
	.finally(() => {
		api.enableLogoutInterceptor()
		if (to.path == '/' && authenticated) {
			next('/country-select')
		} else if (to.path != '/' && to.path != '/about' && !authenticated) {
			next('/')
		} else if (to.path == '/country-select' && store.state.country != '') {
			next('/batch-manager')
		} else {
			next()
		}
	})
})

api.enableLogoutInterceptor()

app.mount('#app')
