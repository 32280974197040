import axios from 'axios'
import router from '../router'
import store from '../store'

export default class Api {
	client: any
	logoutInterceptor: any

	constructor() {
		this.client = axios.create({
			baseURL: process.env.VUE_APP_API_BASE_URL,
			headers: {'accept': 'application/json'},
		})
	}

	get(url: string, params = {}) : any {
		return this.client.request({
			method: 'get',
			url: url,
			params: params,
		})
	}

	getStream(url: string, params = {}) : any {
		return this.client.request({
			method: 'get',
			url: url,
			params: params,
			responseType: 'stream',
		})
	}

	post(url: string, data = {}): any {
		return this.client.request({
			method: 'post',
			url: url,
			data: data,
		})
	}

	enableLogoutInterceptor() {
		const api = this
		this.logoutInterceptor = this.client.interceptors.response.use(
			function(response: any) {
				return response
			},
			function(error: any) {
				if(error.response.status == '401') {
					store.dispatch('logout', api)
					router.push('/')
				}
				return error
			})
	}

	disableLogoutInterceptor() {
		this.client.interceptors.response.eject(this.logoutInterceptor)
	}

	login(username: string, password: string):  any {
		// TODO can it just be a dict
		const postData = new URLSearchParams()
		postData.append('grant_type', '')
		postData.append('username', username)
		postData.append('password', password)
		postData.append('scope', '')
		postData.append('client_id', '')
		postData.append('client_secret', '')

		return this.post('/auth/login', postData)
	}

	getUser(): any {
		return this.get('/auth/user')
	}

	getCountries(): any {
		return this.get('/country/all')
	}

	checkCountryIso(iso: string): any {
		return this.get('/country/iso/validate', {'country_iso': iso})
	}

	allocateCards(countryIso: string, amount: number): any {
		const postData = {
			'country_iso': countryIso,
			'amount': amount,
		}
		return this.post('/card/allocate', postData)
	}

	getBatches(countryIso: string,
		   offset: number = 0,
		   limit: number = -1): any {
		const params = {
			'country_iso': countryIso,
			'since': '2021-12-02T00:00:00.000Z',
			'offset': offset,
			'limit': limit,
		}
		return this.get('/card/batches', params)
	}

	getBatchCreator(batchId: number): any {
		return this.get(`/card/batch/${batchId}/creator`)
	}

	exportBatch(batchId: number): any {
		return this.get(`/card/batch/${batchId}/export`)
	}

	downloadBatch(batchId: number): any {
		return this.getStream(`/card/batch/${batchId}/csv`)
	}
}
