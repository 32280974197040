
import { useI18n } from 'vue-i18n'
export default {
	name: 'App',
	setup() {
		const { t } = useI18n({
			inheritLocale: true,
			useScope: 'global',
		})
    		return { t }
	},
	data() {
		return {
			loggedIn: false,
			countrySelected: false,
			unsubscribeStore: undefined,
		}
	},
	mounted() {
		this.loggedIn = this.$store.state.authenticated
		this.countrySelected = (this.$store.country != '')
		this.unsubscribeStore = this.$store.subscribe((mutation: any, state: any) => {
			if (mutation.type == 'setAuthenticated' || mutation.type == 'unsetAuthenticated') {
				this.loggedIn = state.authenticated
			} else if (mutation.type == 'setCountry' || mutation.type == 'unsetCountry') {
				this.countrySelected = (state.country != '')
			}
		})
	},
	unmounted() {
		this.unsubscribeStore()
	},
	methods: {
		logout() {
			this.$store.dispatch('logout', this.$api)
			this.$router.push('/')
		},
		switchCountry() {
			this.$store.commit('unsetCountry')
			this.$router.push('/country-select')
		},
	}
}
